import React, { useEffect, useState } from "react";
import "../../Components/NavBar/Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../img/Header Logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { FaRegRegistered } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";
import { Button } from "antd";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [navbarCollapse, setNavbarCollapsed] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavbarCollapsed(false); // Reset navbar collapse state
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling to top
    });
  }, [pathname]);

  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand to="/">
            <Link to="/">
              <div className="Logo-Con">
                <img src={Logo} alt="" />
              </div>
            </Link>
          </Navbar.Brand>
          <Button
            className="d-block d-md-none"
            style={{ height: 45 }}
            onClick={() => setNavbarCollapsed((prev) => !prev)}
          >
            <GiHamburgerMenu style={{ fontSize: "1.4rem" }} />
          </Button>
          <Navbar.Collapse
            className={`navbar-collapse collapse ${navbarCollapse && "show"}`}
            id="responsive-navbar-nav"
          >
            <Nav className="my-2 my-lg-0 d-flex d-lg-none" navbarScroll>
              <Nav.Link to="/">
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link to="/about">
                <Link to="/about-us">About Us</Link>
              </Nav.Link>
              <Nav.Link to="/services">
                <Link to="/services">Services</Link>
              </Nav.Link>
              <Nav.Link to="/award">
                <Link to="/award ">Awards</Link>
              </Nav.Link>
              <Nav.Link to="/new-construction">
                <Link to="/new-construction">New Construction</Link>
              </Nav.Link>
              <Nav.Link to="/residential-property">
                <Link to="/residential-property">residential property</Link>
              </Nav.Link>
              <Nav.Link to="/commercial-property">
                <Link to="/commercial-property">commercial property</Link>
              </Nav.Link>
              <Nav.Link to="/contact-us">
                <Link to="/contact-us">Contact us</Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="conatacInfo d-none d-lg-block">
            <div className="contactDiv">
              <div className="icon">
                <FaRegRegistered />
              </div>
              <p>RERA No – A51800040408</p>
            </div>
            <div className="contactDiv">
              <div className="icon">
                <IoMdMail />
              </div>
              <NavLink to="mailto:info@sdgrealty.in">info@sdgrealty.in</NavLink>
            </div>
            <div className="contactDiv">
              <div className="icon">
                <SiGooglemaps />
              </div>
              <p>
                SDG Realty, 37/1468, Ground Floor, Sahyadri Chs, Next to “The
                Club”, D.N. Nagar, Andheri (W), Mumbai - 400 053.
              </p>
            </div>
          </div>
        </Container>
      </Navbar>
      <Nav
        className="my-2 my-lg-0 newNavBar d-none d-lg-flex"
        style={{ maxHeight: "100px" }}
        navbarScroll
      >
        <Nav.Link to="/">
          <Link to="/">Home</Link>
        </Nav.Link>
        <Nav.Link to="/about">
          <Link to="/about-us">About Us</Link>
        </Nav.Link>
        <Nav.Link to="/services">
          <Link to="/services">Services</Link>
        </Nav.Link>
        <Nav.Link to="/award">
          <Link to="/award ">Awards</Link>
        </Nav.Link>
        <Nav.Link to="/new-construction">
          <Link to="/new-construction">New Construction</Link>
        </Nav.Link>
        <Nav.Link to="/residential-property">
          <Link to="/residential-property">residential property</Link>
        </Nav.Link>
        <Nav.Link to="/commercial-property">
          <Link to="/commercial-property">commercial property</Link>
        </Nav.Link>
        <Nav.Link to="/contact-us">
          <Link to="/contact-us">Contact us</Link>
        </Nav.Link>
      </Nav>
    </>
  );
};

export default Header;
