import React, { useState } from "react";
import "../Css/MeetTheTeam.css";
import { NavLink } from "react-router-dom";
import { IoCall } from "react-icons/io5";

import SHAILESHADOSHI from "../img/team/SHAILESH A DOSHI.jpeg";
import JAYSDOSHI from "../img/team/JAY S DOSHI.jpeg";
import SAAHILSDOSHI from "../img/team/SAAHIL S DOSHI.jpeg";
import SUMITSHELAR from "../img/team/SUMIT SHELAR.jpeg";
import SUNILPRAJAPATI from "../img/team/SUNIL PRAJAPATI.jpeg";
import RINKIJAISWAR from "../img/team/RINKI JAISWAR.jpeg";

const MeetTheTeam = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to handle click on image
  const handleImageClick = (name, designation, img) => {
    setSelectedMember({ name, designation, img });
    setShowModal(true);
  };

  const team = [
    {
      name: "SHAILESH A DOSHI",
      role: "Founder",
      img: SHAILESHADOSHI,
      phone: "9820035925",
    },
    {
      name: "JAY S DOSHI",
      role: "Partner",
      img: JAYSDOSHI,
      phone: "9870847778",
    },
    {
      name: "SAAHIL S DOSHI",
      role: "Partner",
      img: SAAHILSDOSHI,
      phone: "9029291424",
    },
    {
      name: "SUMIT SHELAR",
      role: "Sales & Marketing Executive ",
      img: SUMITSHELAR,
      phone: "9920971424",
    },
    {
      name: "SUNIL PRAJAPATI",
      role: "Sales & Marketing Executive",
      img: SUNILPRAJAPATI,
      phone: "9833332414",
    },
    {
      name: "RINKI JAISWAR ",
      role: "Marketing Executive",
      img: RINKIJAISWAR,
      phone: "7025551424",
    },
  ];

  return (
    <>
      <section className="about-section-2-con">
        <div className="container">
          <div className="headTitle">
            <h2>
              <span> Meet Our </span> Team
            </h2>
          </div>

          <div className="row">
            {team.map((value, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="teamCard">
                  <img src={value.img} />
                  <div className="teamDetail">
                    <h5>{value.name}</h5>
                    <p>{value.role}</p>
                    {value.phone !== "" && (
                      <NavLink target="_blank" to={`tel:+91 ${value.phone}`}>
                        <IoCall /> +91 {value.phone}
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MeetTheTeam;
