import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import "../Css/FilterProperty.css";
import { Pagination, Spin } from "antd";
import { get } from "../services/ApiRouting";
import proprtydemo from "../img/demo image.avif";
import SearchProps from "./SearchProps";

// utils/priceFormatter.js

export const formatPrice = (price) => {
  const number = parseInt(price);
  if (number >= 10000000) {
    return (number / 10000000).toFixed(2) + " Cr";
  } else if (number >= 100000) {
    return (number / 100000).toFixed(2) + " Lacs";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(2) + " K";
  } else {
    return number.toString();
  }
};

const CommercialProperty = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [loading, setLoading] = useState(false);

  const [residental, setResidental] = useState([]);

  const [searchDetail, setSearchDetail] = useState({
    propertyType: null,
    configuration: null,
    locality: null,
    maxPrice: null,
    minPrice: null,
  });
  // fetch api
  const fetchData = async (page, limit) => {
    try {
      setLoading(true);

      // Initialize searchData as an object
      let searchData = {};

      if (searchDetail.propertyType) {
        searchData.property_for = searchDetail.propertyType.toLowerCase();
      }
      if (searchDetail.configuration) {
        searchData.property_categ = searchDetail.configuration;
      }
      if (searchDetail.locality) {
        searchData.property_locality = searchDetail.locality;
      }
      if (searchDetail.minPrice && searchDetail.maxPrice) {
        searchData.min_price = searchDetail.minPrice;
        searchData.max_price = searchDetail.maxPrice;
      }

      // Build query string
      const queryString = Object.keys(searchData)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(searchData[key])}`
        )
        .join("&");

      // Fetch data from the API
      const response = await get(
        `/property.php?action=fetch&property_type=commercial&limit=${limit}&page=${page}${
          queryString ? `&${queryString}` : ""
        }`
      );

      setLoading(false);
      setResidental(response.list);
      setTotal(response.total);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const dbounceData = setTimeout(() => {
      fetchData(page, limit);
    }, 300);
    return () => clearTimeout(dbounceData);
  }, [page, limit, searchDetail]);

  return (
    <Spin spinning={loading}>
      {/* =========== Property Section 1 ========== */}
      <div className="property-Section1">
        <div className="propertyBanner">
          <div className="container">
            <div className="row paddingSection">
              <div className="col-md-12">
                <div className="property_BannerDesc">
                  <h1>Commercial Property</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {residental.length > 0 && (
        <SearchProps type="commercial" setSearchDetail={setSearchDetail} />
      )}
      {/* =========== Property Section 2 ========== */}

      <section className="property-Section2">
        <div className="container">
          <div className="headTitle">
            <h2>PROPERTY LIST</h2>
          </div>
          <div className="row">
            {residental.length > 0 ? (
              <>
                {residental &&
                  residental.map((ele) => {
                    const {
                      images,
                      property_for,
                      property_name,
                      property_parking,
                      parking,
                      property_price,
                      property_slug,
                      property_bathroom,
                      property_configuration,
                      property_locality,
                      price_type,
                    } = ele;
                    const imageUrl =
                      images.length > 0 ? images[0].image_link : proprtydemo;
                    return (
                      <div className="col-lg-4 col-md-6" key={property_slug}>
                        <div className="package-item">
                          <div className="home_proImg">
                            <img
                              className="img-fluid"
                              src={imageUrl}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = proprtydemo;
                              }}
                            />
                            <div className="home_proTxt1">
                              <span
                                className="home_probtn1"
                                style={{ textTransform: "uppercase" }}
                              >
                                {ele.property_auto_id}
                              </span>
                              <a
                                className={`home_probtn2 ${
                                  property_for === "outright"
                                    ? "outright"
                                    : property_for === "lease"
                                    ? "lease"
                                    : ""
                                }`}
                                href="#"
                              >
                                {property_for}
                              </a>
                            </div>
                          </div>
                          <div className="home_prodesc">
                            <h2>{property_name}</h2>
                            <p>{property_locality}</p>
                            <div className="row">
                              <div className="col-4">
                                <div className="home_prolist">
                                  <h6 className="d-flex align-items-center home_prolist">
                                    {property_configuration}
                                  </h6>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="home_prolist">
                                  <h6 className="d-flex align-items-center home_prolist">
                                    {property_bathroom}&nbsp;Bathroom
                                  </h6>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="home_prolist">
                                  <h6>{property_parking}&nbsp;Parking</h6>

                                  <span> {parking}</span>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-6">
                                <div className="home_proprice">
                                  <h5>
                                    <span>
                                      {price_type === "Price on request"
                                        ? price_type
                                        : formatPrice(property_price)}
                                    </span>
                                  </h5>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="pro_btn">
                                  <Link to={`/${property_slug}`}>Details</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="property-section-2-not-found-image-con py-5">
                <div className="property-section-2-not-found-image">
                  <img src={proprtydemo} alt="" />
                  <h3>Property Not Found</h3>
                </div>
              </div>
            )}
          </div>
          <div className="pagination-main-con">
            <Pagination
              hideOnSinglePage
              className={!residental && "d-none"}
              current={page}
              total={total}
              pageSize={limit}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
      </section>
      {/* <PopUp /> */}
      <Footer />
    </Spin>
  );
};

export default CommercialProperty;
