import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { get } from "../services/ApiRouting";
import LeaseData from "./LeaseData";
import MacleaseData from "./MacleaseData";
import OutrightData from "./OutrightData";
import MaxOutrightData from "./MaxOutRightData";

const propertiesType = [
  {
    name: "Apartment / Flat",
    type: "residential",
  },
  {
    name: "Bungalow",
    type: "residential",
  },
  {
    name: "Duplex",
    type: "residential",
  },
  {
    name: "Triplex",
    type: "residential",
  },
  {
    name: "Penthouse",
    type: "residential",
  },
  {
    name: "Row House",
    type: "residential",
  },
  {
    name: "Condominium (Condo)",
    type: "residential",
  },
  {
    name: "Builder Floor",
    type: "residential",
  },
  {
    name: "Farm House",
    type: "residential",
  },
  {
    name: "Gated Community",
    type: "residential",
  },
  {
    name: "Studio Apartment",
    type: "residential",
  },
  {
    name: "Shop",
    type: "commercial",
  },
  {
    name: "Office",
    type: "commercial",
  },
  {
    name: "Showroom",
    type: "commercial",
  },
  {
    name: "Commercial Space",
    type: "commercial",
  },
  {
    name: "Commercial Building",
    type: "commercial",
  },
  {
    name: "Restaurant Space",
    type: "commercial",
  },
  {
    name: "Residence + Commercial",
    type: "commercial",
  },
  {
    name: "Garage",
    type: "commercial",
  },
  {
    name: "Warehouse / Godown",
    type: "commercial",
  },
];

export default function SearchProps({ type, setSearchDetail }) {
  const [form] = Form.useForm();
  const [localityData, setLocalityData] = useState();
  const [priceDetail, setPriceDetail] = useState(null);

  const fetchLocalityData = async () => {
    try {
      const response = await get(`/property.php?action=fetchLocation`);
      setLocalityData(response.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchLocalityData();
    }, 300);

    return () => clearTimeout(dbounce);
  }, []);

  const onFinish = (values) => {
    setSearchDetail({
      propertyType: values.property_type,
      configuration: values.configuration,
      locality: values.locality,
      maxPrice: values.max_price,
      minPrice: values.min_price,
    });
  };

  const onClear = () => {
    form.resetFields();
    setSearchDetail({
      propertyType: null,
      configuration: null,
      locality: null,
      maxPrice: null,
      minPrice: null,
    });
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <div className="container">
        <div className="property-filter-inputs-main-con">
          <div className="first-filter-con-main">
            <div className="row">
              <div className="col-md-2 filter-border-con">
                <div className="property-filter-inputs">
                  <Form.Item name="property_type">
                    <Select
                      className="property-select-color"
                      placeholder="Select"
                      onChange={(val) => setPriceDetail(val)}
                    >
                      <Select.Option value="Outright">Outright</Select.Option>
                      <Select.Option value="Lease">Lease</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-3 filter-border-con">
                <div className="property-filter-inputs">
                  <Form.Item name="locality">
                    <Select
                      className="property-select-color"
                      placeholder="Locality"
                    >
                      {localityData &&
                        localityData.map((elem, index) => (
                          <Select.Option
                            key={index}
                            value={elem.property_locality}
                          >
                            {elem.property_locality}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-3 filter-border-con">
                <div className="property-filter-inputs">
                  <Form.Item name="configuration">
                    <Select
                      className="property-select-color"
                      placeholder="Select Configuration"
                    >
                      {propertiesType
                        .filter((val) => type === val.type)
                        .map((val) => (
                          <Select.Option value={val.name}>
                            {val.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-2 filter-border-con">
                <div className="property-filter-inputs">
                  <Form.Item name="min_price">
                    {priceDetail === "Lease" ? (
                      <Select
                        className="property-select-color"
                        placeholder="Min Price"
                      >
                        {LeaseData &&
                          LeaseData.map((elem, index) => (
                            <Select.Option key={index} value={elem.price}>
                              {elem.mainPrice}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        className="property-select-color"
                        placeholder="Min Price"
                      >
                        {OutrightData &&
                          OutrightData.map((elem, index) => (
                            <Select.Option key={index} value={elem.price}>
                              {elem.mainPrice}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-2">
                <div className="property-filter-inputs">
                  <Form.Item name="max_price">
                    {priceDetail === "Lease" ? (
                      <Select
                        className="property-select-color"
                        placeholder="Max Price"
                      >
                        {MacleaseData &&
                          MacleaseData.map((elem, index) => (
                            <Select.Option key={index} value={elem.price}>
                              {elem.mainPrice}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        className="property-select-color"
                        placeholder="Max Price"
                      >
                        {MaxOutrightData &&
                          MaxOutrightData.map((elem, index) => (
                            <Select.Option key={index} value={elem.price}>
                              {elem.mainPrice}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="second-filter-con-main">
            <div className="second-filter-under-con">
              <div className="row">
                <div className="col-md-6">
                  <div className="property-filter-inputs property-filter-inputs-search">
                    <Form.Item>
                      <Button htmlType="submit">
                        <IoSearch /> Search
                      </Button>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="property-filter-inputs property-filter-inputs-clear">
                    <Form.Item>
                      <Button onClick={onClear}>Clear</Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
