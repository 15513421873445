import React, { useEffect, useState } from "react";
import { BiBed, BiBath } from "react-icons/bi";
import PropertyData from "../Components/PropertyData";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import { LuParkingCircle } from "react-icons/lu";
import "../Css/Property.css";

const Properties = () => {
  const search = useLocation();
  const [type, setType] = useState(null);
  const [propertyType, setPropertyType] = useState(null);

  const path = search.pathname;

  useEffect(() => {
    const data = path.split("/");
    setType(data[1]);
    setPropertyType(data[2]);
  }, [path]);

  return (
    <div>
      {/* =========== Property Section 1 ========== */}
      <div className="property-Section1">
        <div className="propertyBanner">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="property_BannerDesc">
                  <h1>
                    {type} {propertyType} Property List
                  </h1>
                  <p>
                    The apartments will have large windows and balconies to
                    allow for natural light and oxygenated air.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========== Property Section 2 ========== */}

      <section className="property-Section2">
        <div className="container">
          <div className="headTitle">
            <h2>PROPERTY LIST</h2>
          </div>
          <div className="row">
            {PropertyData.filter(
              (value) =>
                value.type === type && value.propertyType === propertyType
            ).map((ele) => {
              return (
                <>
                  <div className="col-lg-4 mb-lg-5">
                    <div className="package-item">
                      <div className="home_proImg">
                        <img className="img-fluid" src={ele.img} />
                        <div className="home_proTxt1">
                          <span
                            className="home_probtn1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {ele.property_auto_id}
                          </span>
                          <a className="home_probtn2" href="">
                            {ele.type}
                          </a>
                        </div>
                      </div>
                      <div className="home_prodesc">
                        <h2>{ele.name}</h2>
                        <p>{ele.address}</p>
                        <div class="row">
                          <div className="col-4">
                            <div className="home_prolist">
                              <h6>BedRooms</h6>
                              <i>
                                <BiBed />
                              </i>
                              <span> {ele.bathroom}</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="home_prolist">
                              <h6>Parking</h6>
                              <i>
                                <LuParkingCircle />
                              </i>
                              <span> {ele.parking}</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="home_prolist">
                              <h6>Area</h6>
                              <span>{ele.carpet_area}</span>
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-6">
                            <div class="home_proprice">
                              <h5>
                                For Sale <span> {ele.price}</span>
                              </h5>
                            </div>
                          </div>
                          <div className="col-6">
                            <div class="pro_btn">
                              <Link
                                to={`/${ele.type}/${ele.propertyType}/${ele.slugs}`}
                              >
                                Details
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/* <section className="property-Section2 ">
        <div className="container">
          <div className="headTitle">
            <h2>Property List</h2>
          </div>
          <div className="row">
            {propertyList.map((ele) => {
              const {propertyImg, propertyName, propertyAddress, propertyPrice,  propertyBedRooms,  propertyBathrooms, propertyArea} = ele;

              return (
                <>
                  <div className="col-lg-4 mb-lg-5">
                    <div className="procard" style={{backgroundImage:`linear-gradient(90deg, rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6) 100%),url(${propertyImg})`}}>
                      <div class="pro_desc">
                        <h3>{propertyName}</h3>
                        <p>{propertyAddress}</p>
                        <div class="row">
                          <div className="col-4">
                            <div className="pro_list">
                              <h6>BedRooms</h6>
                              <i>
                                <BiBed />
                              </i>
                              <span>{propertyBedRooms}</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="pro_list">
                              <h6>Bathrooms</h6>
                              <i>
                                <BiBath />
                              </i>{" "}
                              <span>{propertyBathrooms}</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="pro_list">
                              <h6>Area</h6>
                              <span>
                                {propertyArea}
                              </span>
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div class="pro_price">
                              <h5>
                                For Sale <span> {propertyPrice}</span>
                              </h5>
                            </div>
                          </div>
                          <div className="col-6">
                            <div class="pro_btn">
                              <Link to="/PropertyView">Details</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section> */}
      <Footer />
      {/* <PopUp/> */}
    </div>
  );
};

export default Properties;
