
const PropertyData = [
  {
    id: "1",
    img:"https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg",
    type: "outright",
    propertyType: "residential",
    name: "Suraj Park View",
    slugs: "suraj-park-view",
    built_up_area: "1500 sq. ft.",
    carpet_area: "1200 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "4th Floor",
    bathroom: "2",
    price: "₹1,50,00,000",
    parking: "Available",
    locality: "Locality 1",
    address: "Address 1, City, State, PIN",
    desc: "Beautiful residential property with modern amenities.",
    furniture: ["Sofa", "Fridge"],
    amenities: ["Balcony", "Gym"],
    additional_feature: ["Parking", "Lift"],
  },
  {
    id: "2",
    img:"https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
    type: "outright",
    propertyType: "residential",
    name: "Happy Homes",
    slugs: "happy-homes",
    built_up_area: "1800 sq. ft.",
    carpet_area: "1400 sq. ft.",
    condition: "Renovated",
    status: "Available",
    floor: "5th Floor",
    bathroom: "3",
    price: "₹1,80,00,000",
    parking: "Available",
    locality: "Locality 2",
    address: "Address 2, City, State, PIN",
    desc: "Spacious residential property with all modern facilities.",
    furniture: ["Sofa", "Dining Table"],
    amenities: ["Swimming Pool", "Clubhouse"],
    additional_feature: ["Power Backup", "Security"],
  },
  {
    id: "3",
    img:"https://assets-news.housing.com/news/wp-content/uploads/2022/04/07013406/ELEVATED-HOUSE-DESIGN-FEATURE-compressed.jpg",
    type: "outright",
    propertyType: "residential",
    name: "Windermere Apt",
    slugs: "windermere-apt",
    built_up_area: "1600 sq. ft.",
    carpet_area: "1300 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "3rd Floor",
    bathroom: "2",
    price: "₹1,60,00,000",
    parking: "Available",
    locality: "Locality 3",
    address: "Address 3, City, State, PIN",
    desc: "Modern residential property in a prime location.",
    furniture: ["Bed", "Wardrobe"],
    amenities: ["Garden", "Play Area"],
    additional_feature: ["Intercom", "CCTV"],
  },
  {
    id: "4",
    img:"https://www.larisarealtech.com/wp-content/uploads/2023/05/A-Villa-House-for-Rent.jpg",
    type: "outright",
    propertyType: "residential",
    name: "Lodha Matunga",
    slugs: "lodha-matunga",
    built_up_area: "2000 sq. ft.",
    carpet_area: "1600 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "2nd Floor",
    bathroom: "3",
    price: "₹2,00,00,000",
    parking: "Available",
    locality: "Locality 4",
    address: "Address 4, City, State, PIN",
    desc: "Luxurious residential property with premium amenities.",
    furniture: ["TV", "Washing Machine"],
    amenities: ["Gym", "Sauna"],
    additional_feature: ["Clubhouse", "Parking"],
  },
  {
    id: "5",
    img:"https://www.thehousedesigners.com/images/plans/01/URD/bulk/6583/the-destination-front-rendering_m.webp",
    type: "outright",
    propertyType: "residential",
    name: "Evana Apt",
    slugs: "evana-apt",
    built_up_area: "1700 sq. ft.",
    carpet_area: "1400 sq. ft.",
    condition: "Ready to move",
    status: "Available",
    floor: "6th Floor",
    bathroom: "2",
    price: "₹1,70,00,000",
    parking: "Available",
    locality: "Locality 5",
    address: "Address 5, City, State, PIN",
    desc: "Ready to move in residential property with all amenities.",
    furniture: ["Dining Table", "Microwave"],
    amenities: ["Play Area", "Parking"],
    additional_feature: ["Lift", "Power Backup"],
  },
  {
    id: "6",
    img:"https://www.bhg.com/thmb/H9VV9JNnKl-H1faFXnPlQfNprYw=/1799x0/filters:no_upscale():strip_icc()/white-modern-house-curved-patio-archway-c0a4a3b3-aa51b24d14d0464ea15d36e05aa85ac9.jpg",
    type: "outright",
    propertyType: "residential",
    name: "Cassias Apt",
    slugs: "cassias-apt",
    built_up_area: "1900 sq. ft.",
    carpet_area: "1500 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "7th Floor",
    bathroom: "3",
    price: "₹1,90,00,000",
    parking: "Available",
    locality: "Locality 6",
    address: "Address 6, City, State, PIN",
    desc: "Newly constructed residential property with modern design.",
    furniture: ["Sofa", "Wardrobe"],
    amenities: ["Garden", "Swimming Pool"],
    additional_feature: ["Security", "Intercom"],
  },
  {
    id: "7",
    img:"https://media.istockphoto.com/id/511061090/photo/business-office-building-in-london-england.jpg?s=612x612&w=0&k=20&c=nYAn4JKoCqO1hMTjZiND1PAIWoABuy1BwH1MhaEoG6w=",
    type: "outright",
    propertyType: "commercial",
    name: "Water Frount",
    slugs: "water-frount",
    built_up_area: "3000 sq. ft.",
    carpet_area: "2500 sq. ft.",
    condition: "Ready to move",
    status: "Available",
    floor: "Ground Floor",
    bathroom: "3",
    price: "₹3,00,00,000",
    parking: "Available",
    locality: "Commercial Area 1",
    address: "Commercial Address 1, City, State, PIN",
    desc: "Prime commercial property suitable for offices or shops.",
    furniture: ["Sofa", "Reception Desk"],
    amenities: ["Parking", "Lift"],
    additional_feature: ["Power Backup", "CCTV"],
  },
  {
    id: "8",
    img:"https://cdn.create.vista.com/api/media/small/273020498/stock-photo-bottom-view-of-modern-skyscrapers-in-business-district-against-b",
    type: "outright",
    propertyType: "commercial",
    name: "81 Crest",
    slugs: "81-crest",
    built_up_area: "3500 sq. ft.",
    carpet_area: "3000 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "1st Floor",
    bathroom: "4",
    price: "₹3,50,00,000",
    parking: "Available",
    locality: "Commercial Area 2",
    address: "Commercial Address 2, City, State, PIN",
    desc: "Newly built commercial property in a bustling area.",
    furniture: ["Workstations", "Chairs"],
    amenities: ["Parking", "Conference Room"],
    additional_feature: ["Security", "Power Backup"],
  },
  {
    id: "9",
    img:"https://images.squarespace-cdn.com/content/v1/5820b938d482e9a9a7034dea/1595523618537-HF0TO5T0B2383WDN8GDA/photo-1462396240927-52058a6a84ec.jpg?format=1000w",
    type: "outright",
    propertyType: "commercial",
    name: "Webtach",
    slugs: "webtach",
    built_up_area: "3200 sq. ft.",
    carpet_area: "2800 sq. ft.",
    condition: "Renovated",
    status: "Available",
    floor: "2nd Floor",
    bathroom: "3",
    price: "₹3,20,00,000",
    parking: "Available",
    locality: "Commercial Area 3",
    address: "Commercial Address 3, City, State, PIN",
    desc: "Renovated commercial space ideal for startups.",
    furniture: ["Cubicles", "Conference Table"],
    amenities: ["Lift", "Parking"],
    additional_feature: ["CCTV", "Intercom"],
  },
  {
    id: "10",
    img:"https://blog.tkelevator.com/wp/wp-content/uploads/2018/06/shutterstock_464879318-scaled.jpg",
    type: "outright",
    propertyType: "commercial",
    name: "Moti Mahal",
    slugs: "moti-mahal",
    built_up_area: "4000 sq. ft.",
    carpet_area: "3500 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "3rd Floor",
    bathroom: "5",
    price: "₹4,00,00,000",
    parking: "Available",
    locality: "Commercial Area 4",
    address: "Commercial Address 4, City, State, PIN",
    desc: "Spacious commercial property with excellent amenities.",
    furniture: ["Desks", "Storage Cabinets"],
    amenities: ["Gym", "Cafeteria"],
    additional_feature: ["Power Backup", "Security"],
  },
  {
    id: "11",
    img:"https://media.istockphoto.com/id/1368536999/video/time-lapse-low-angle-of-tall-corporate-buildings-skyscraper-in-hong-kong.jpg?s=640x640&k=20&c=9g_QihyhBEtSvMihrb48bPjp1E-UDi_t8JvZhjbDMt8=",
    type: "outright",
    propertyType: "commercial",
    name: "Commercial Property 5",
    slugs: "outright/commercial-property/property-5",
    built_up_area: "3700 sq. ft.",
    carpet_area: "3200 sq. ft.",
    condition: "Ready to move",
    status: "Available",
    floor: "4th Floor",
    bathroom: "4",
    price: "₹3,70,00,000",
    parking: "Available",
    locality: "Commercial Area 5",
    address: "Commercial Address 5, City, State, PIN",
    desc: "Ready to move in commercial property with all facilities.",
    furniture: ["Reception Desk", "Workstations"],
    amenities: ["Parking", "Lift"],
    additional_feature: ["CCTV", "Security"],
  },
  {
    id: "12",
    img:"https://cpmconsulting.rs/wp-content/uploads/2015/08/blue-buildings.jpg",
    type: "outright",
    propertyType: "commercial",
    name: "Notan Classic",
    slugs: "notan-classic",
    built_up_area: "3600 sq. ft.",
    carpet_area: "3100 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "5th Floor",
    bathroom: "4",
    price: "₹3,60,00,000",
    parking: "Available",
    locality: "Commercial Area 6",
    address: "Commercial Address 6, City, State, PIN",
    desc: "Newly constructed commercial space in a prime location.",
    furniture: ["Conference Table", "Office Chairs"],
    amenities: ["Parking", "Power Backup"],
    additional_feature: ["Lift", "Security"],
  },
  {
    id: "13",
    img:"https://i.pinimg.com/736x/be/d5/8e/bed58e0fea0bec4c3924858a2a13cd5d.jpg",
    type: "lease",
    propertyType: "residential",
    name: "Vertical Bliss",
    slugs: "vertical-bliss",
    built_up_area: "1200 sq. ft.",
    carpet_area: "1000 sq. ft.",
    condition: "Furnished",
    status: "Available",
    floor: "2nd Floor",
    bathroom: "2",
    rent: "₹50,000/month",
    parking: "Available",
    locality: "Locality 1",
    address: "Address 1, City, State, PIN",
    desc: "Fully furnished residential property available for lease.",
    furniture: ["Bed", "Sofa", "Dining Table"],
    amenities: ["Balcony", "Gym"],
    additional_feature: ["Power Backup", "Security"],
  },
  {
    id: "14",
    img:"https://res.akamaized.net/domain/image/upload/t_web/v1538713881/bigsmall_Mirvac_house2_twgogv.jpg",
    type: "lease",
    propertyType: "residential",
    name: "Mohid Heights Apt",
    slugs: "mohid-heights-apt",
    built_up_area: "1400 sq. ft.",
    carpet_area: "1100 sq. ft.",
    condition: "Semi-Furnished",
    status: "Available",
    floor: "3rd Floor",
    bathroom: "3",
    rent: "₹60,000/month",
    parking: "Available",
    locality: "Locality 2",
    address: "Address 2, City, State, PIN",
    desc: "Spacious semi-furnished residential property for lease.",
    furniture: ["Wardrobe", "Fridge"],
    amenities: ["Swimming Pool", "Clubhouse"],
    additional_feature: ["Lift", "Parking"],
  },
  {
    id: "15",
    img:"https://images.adsttc.com/media/images/5ecd/d4ac/b357/65c6/7300/009d/newsletter/02C.jpg?1590547607",
    type: "lease",
    propertyType: "residential",
    name: "Mayfair Bandra",
    slugs: "mayfair-bandra",
    built_up_area: "1600 sq. ft.",
    carpet_area: "1300 sq. ft.",
    condition: "Unfurnished",
    status: "Available",
    floor: "4th Floor",
    bathroom: "2",
    rent: "₹45,000/month",
    parking: "Available",
    locality: "Locality 3",
    address: "Address 3, City, State, PIN",
    desc: "Unfurnished residential property with great potential for lease.",
    furniture: [],
    amenities: ["Garden", "Play Area"],
    additional_feature: ["Security", "Power Backup"],
  },
  {
    id: "16",
    img:"https://images.adsttc.com/media/images/5666/3181/e58e/ce70/b600/05d8/newsletter/portada_29_Pavilion_House.jpg?1449537899",
    type: "lease",
    propertyType: "residential",
    name: "Vidhata Apt",
    slugs: "vidhata-apt",
    built_up_area: "1800 sq. ft.",
    carpet_area: "1500 sq. ft.",
    condition: "Furnished",
    status: "Available",
    floor: "5th Floor",
    bathroom: "3",
    rent: "₹70,000/month",
    parking: "Available",
    locality: "Locality 4",
    address: "Address 4, City, State, PIN",
    desc: "Luxurious fully furnished residential property available for lease.",
    furniture: ["TV", "Washing Machine"],
    amenities: ["Gym", "Sauna"],
    additional_feature: ["Clubhouse", "Parking"],
  },
  {
    id: "17",
    img:"https://www.wilsonhomes.com.au/sites/default/files/styles/blog_hero_banner/public/My%20project%20-%202023-06-20T095818.329%20%281%29_0.jpg?itok=UbtVbhT0",
    type: "lease",
    propertyType: "residential",
    name: "Wagh Manor",
    slugs: "wagh-manor",
    built_up_area: "1500 sq. ft.",
    carpet_area: "1200 sq. ft.",
    condition: "Semi-Furnished",
    status: "Available",
    floor: "6th Floor",
    bathroom: "2",
    rent: "₹55,000/month",
    parking: "Available",
    locality: "Locality 5",
    address: "Address 5, City, State, PIN",
    desc: "Semi-furnished residential property with modern amenities.",
    furniture: ["Sofa", "Dining Table"],
    amenities: ["Play Area", "Parking"],
    additional_feature: ["Lift", "Power Backup"],
  },
  {
    id: "18",
    img:"https://cdn11.bigcommerce.com/s-g95xg0y1db/images/stencil/1280x1280/g/modern%20house%20plan%20-%20carbondale__05776.original.jpg",
    type: "lease",
    propertyType: "commercial",
    name: "Indra Bhavan",
    slugs: "Indra Bhavan",
    built_up_area: "3000 sq. ft.",
    carpet_area: "2500 sq. ft.",
    condition: "Ready to move",
    status: "Available",
    floor: "Ground Floor",
    bathroom: "3",
    rent: "₹1,00,000/month",
    parking: "Available",
    locality: "Commercial Area 1",
    address: "Commercial Address 1, City, State, PIN",
    desc: "Prime commercial property suitable for offices or shops.",
    furniture: ["Workstations", "Chairs"],
    amenities: ["Parking", "Lift"],
    additional_feature: ["Power Backup", "CCTV"],
  },
  {
    id: "19",
    img:"https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg",
    type: "lease",
    propertyType: "commercial",
    name: "Business Suit 9",
    slugs: "business-suit-9",
    built_up_area: "3500 sq. ft.",
    carpet_area: "3000 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "1st Floor",
    bathroom: "4",
    rent: "₹1,20,000/month",
    parking: "Available",
    locality: "Commercial Area 2",
    address: "Commercial Address 2, City, State, PIN",
    desc: "Newly built commercial property in a bustling area.",
    furniture: ["Cubicles", "Conference Table"],
    amenities: ["Parking", "Conference Room"],
    additional_feature: ["Security", "Power Backup"],
  },
  {
    id: "20",
    img:"https://images.ctfassets.net/wdjnw2prxlw8/6HRSjw4NJnvoQEKuDF9BsM/25bd19e9dfbfa9be0137096f74c454fa/glass_buildings_view_from_street_to_sky.jpg",
    type: "lease",
    propertyType: "commercial",
    name: "Waterfrount",
    slugs: "waterfrount",
    built_up_area: "3200 sq. ft.",
    carpet_area: "2800 sq. ft.",
    condition: "Renovated",
    status: "Available",
    floor: "2nd Floor",
    bathroom: "3",
    rent: "₹90,000/month",
    parking: "Available",
    locality: "Commercial Area 3",
    address: "Commercial Address 3, City, State, PIN",
    desc: "Renovated commercial space ideal for startups.",
    furniture: ["Reception Desk", "Storage Cabinets"],
    amenities: ["Lift", "Parking"],
    additional_feature: ["CCTV", "Intercom"],
  },
  {
    id: "21",
    img:"https://media.istockphoto.com/id/1085962150/photo/blue-building-glass-curtain-wall.jpg?s=612x612&w=0&k=20&c=saCiNoju9V9ASMQmCku87VUi8aMtPi4prySWUwLzPnU=",
    type: "lease",
    propertyType: "commercial",
    name: "Mohid Heights",
    slugs: "mohid-heights",
    built_up_area: "4000 sq. ft.",
    carpet_area: "3500 sq. ft.",
    condition: "New",
    status: "Available",
    floor: "3rd Floor",
    bathroom: "5",
    rent: "₹1,50,000/month",
    parking: "Available",
    locality: "Commercial Area 4",
    address: "Commercial Address 4, City, State, PIN",
    desc: "Spacious commercial property with excellent amenities.",
    furniture: ["Desks", "Storage Cabinets"],
    amenities: ["Gym", "Cafeteria"],
    additional_feature: ["Power Backup", "Security"],
  },
  {
    id: "22",
    img:"https://cdn.create.vista.com/api/media/small/273020498/stock-photo-bottom-view-of-modern-skyscrapers-in-business-district-against-b",
    type: "lease",
    propertyType: "commercial",
    name: "Diamond Apt",
    slugs: "diamond-apt",
    built_up_area: "3700 sq. ft.",
    carpet_area: "3200 sq. ft.",
    condition: "Ready to move",
    status: "Available",
    floor: "4th Floor",
    bathroom: "4",
    rent: "₹1,30,000/month",
    parking: "Available",
    locality: "Commercial Area 5",
    address: "Commercial Address 5, City, State, PIN",
    desc: "Ready to move in commercial property with all facilities.",
    furniture: ["Workstations", "Conference Table"],
    amenities: ["Parking", "Lift"],
    additional_feature: ["CCTV", "Security"],
  },
];
export default PropertyData;
