const OutrightData = [
    {
        price: "1000000",
        mainPrice:"1 Cr",
    },
    {
        price: "15000000",
        mainPrice:"1.5 Cr",
    },
    {
        price: "20000000",
        mainPrice:"2 Cr",
    },
    {
        price: "30000000",
        mainPrice:"3 Cr",
    },
    {
        price: "50000000",
        mainPrice:"5 Cr",
    },
    {
        price: "75000000",
        mainPrice:"7.5 Cr",
    },
    {
        price: "100000000",
        mainPrice:"10 Cr",
    },
    {
        price: "150000000",
        mainPrice:"15 Cr",
    },
    {
        price: "200000000",
        mainPrice:"20 Cr",
    },
    {
        price: "250000000",
        mainPrice:"25 Cr",
    },
    {
        price: "500000000",
        mainPrice:"50 Cr",
    },
]
export default OutrightData;